import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef, Fragment, useMemo } from 'react'

import { DatoGatsbyImage } from '@/features/common/'

interface Props extends ComponentPropsWithoutRef<'figure'> {
  data?: Queries.ArticleGraphicFragment | null
}

export const ArticleGraphic = ({
  data,
  ...props
}: Props): JSX.Element => {
  const { responsiveGraphic } = data || {}
  const sortedResponsiveGraphic = useMemo(() => {
    if (responsiveGraphic) {
      return [...responsiveGraphic].sort(
        (a, b) =>
          (b?.breakpointMax || Infinity) -
          (a?.breakpointMax || Infinity)
      )
    }
  }, [responsiveGraphic])
  const styles = {
    figure: css`
      display: grid;
      grid-template-rows: auto 1fr;
      margin: 0;
      width: 100%;
      margin: 3em 0 1em;
    `,
    caption: css`
      font-style: italic;
      font-size: var(--fs-15);
      margin: 0.5em 0;
      color: #444;
      line-height: 1.5;
    `,
  }
  return (
    <figure
      css={styles.figure}
      {...props}
    >
      {sortedResponsiveGraphic?.map((block, i, array) => {
        const { graphic, breakpointMax } = block || {}
        const imageStyle = css`
          width: 100%;
          display: none;
          ${array.length > 1
            ? breakpointMax
              ? css`
                  @media (max-width: ${breakpointMax}px) {
                    display: flex;
                  }
                `
              : css`
                  @media (min-width: ${array[i + 1]?.breakpointMax ||
                    0 + 1}px) {
                    display: flex;
                  }
                `
            : css`
                display: flex;
              `}
        `
        return (
          <Fragment key={i}>
            {graphic?.format === 'svg' ? (
              <img
                css={imageStyle}
                src={graphic.url || undefined}
                alt={graphic.alt || ''}
              />
            ) : (
              <DatoGatsbyImage
                css={imageStyle}
                image={graphic?.gatsbyImageData}
                alt={graphic?.alt || ''}
              />
            )}
            {graphic?.title && (
              <figcaption css={styles.caption}>
                {graphic.title}
              </figcaption>
            )}
          </Fragment>
        )
      })}
    </figure>
  )
}

export const ArticleGraphicFragment = graphql`
  fragment ArticleGraphic on DatoCmsArticleGraphic {
    __typename
    id: originalId
    responsiveGraphic {
      graphic {
        alt
        title
        gatsbyImageData(width: 1440)
        url
        format
      }
      breakpointMax
    }
  }
`
