import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { bodyHeading } from '@/features/page-sections'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { MatriculationSchool } from './MatriculationSchool'
import { useMatriculationSchoolsQuery } from './hooks/useMatriculationSchoolsQuery'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data: Queries.MatriculationsSectionFragment | null
  bgColor: 'WHITE' | 'GRAY'
}

export const MatriculationsSection = ({
  data,
  bgColor,
  ...props
}: Props): JSX.Element => {
  const matriculationSchools = useMatriculationSchoolsQuery()
  const styles = {
    section: css`
      display: grid;
      background: ${bgColor === 'WHITE'
        ? '#fff'
        : bgColor === 'GRAY' && colors.gray95};
      padding: var(--row-72) var(--margin) var(--row-108);
    `,
    heading: css`
      ${bodyHeading}
      width: fit-content;
      margin-bottom: 1em;
    `,
    schools: css`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: var(--gtr-36);
      row-gap: var(--row-36);
      ${mq().m} {
        grid-template-columns: 1fr 1fr;
      }
      ${mq().s} {
        grid-template-columns: 1fr;
      }
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      <h2 css={styles.heading}>{data?.heading}</h2>
      <div css={styles.schools}>
        {matriculationSchools.map(school => (
          <MatriculationSchool
            data={school}
            key={school.id}
          />
        ))}
      </div>
    </section>
  )
}

export const MatriculationsSectionFragment = graphql`
  fragment MatriculationsSection on DatoCmsMatriculationsSection {
    __typename
    id: originalId
    heading
  }
`
