import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ComponentPropsWithoutRef } from 'react'

import {
  ArticleContainer,
  ArticleHeading,
} from '@/features/article-sections'
import { BodyTextBlock, DatoGatsbyImage } from '@/features/common/'
import { colors } from '@/theme/variables'

import { BlackbaudFormRenderer } from './BlackbaudFormRenderer'
import { GivingLevelSection } from './GivingLevelSection'
import { OnlineGivingFormRenderer } from './OnlineGivingFormRenderer'

interface Props extends ComponentPropsWithoutRef<'article'> {
  data?: Queries.BlackbaudFormFragment | null
  layout: 'PAGE' | 'LIGHTBOX'
  disableLightboxLinks?: boolean
}

export const BlackbaudForm = ({
  data,
  layout,
  disableLightboxLinks,
  ...props
}: Props): JSX.Element => {
  const getFormRenderer = () => {
    const rendererProps = {
      formId: data?.formId,
      bboxVersion: data?.bboxVersion,
      css: styles.form,
    }
    switch (data?.bboxVersion) {
      case 'Online Giving':
        return (
          <OnlineGivingFormRenderer
            oGId={data.oGId}
            {...rendererProps}
          />
        )
      default:
        return <BlackbaudFormRenderer {...rendererProps} />
    }
  }
  const styles = {
    body: css`
      display: contents;
      p,
      ul,
      ol {
        line-height: 1.75;
        max-width: 85ch;
        margin-top: 0;
      }
      > * {
        margin-left: var(--margin);
        margin-right: var(--margin);
      }
      a {
        color: ${colors.red};
        @media (hover: hover) {
          &:hover {
            color: ${colors.redDark};
          }
        }
      }
    `,
    form: css`
      padding: 0 var(--margin);
      box-sizing: border-box;
    `,
    image: css`
      width: 100%;
      max-width: 85ch;
    `,
  }
  let count = 0
  return (
    <ArticleContainer
      layout={layout}
      {...props}
    >
      <ArticleHeading heading={data?.heading} />
      <div css={styles.body}>
        {data?.intro?.map((block, i) => {
          switch (block?.__typename) {
            case 'DatoCmsBodyTextBlock':
              return (
                <BodyTextBlock
                  data={block}
                  key={i}
                  disableLightboxLinks={disableLightboxLinks}
                />
              )
            case 'DatoCmsGivingLevelSection':
              count += 1
              return (
                <GivingLevelSection
                  data={block}
                  css={{
                    background:
                      count % 2 ? colors.gray90 : colors.gray95,
                  }}
                  key={i}
                />
              )
            case 'DatoCmsImageBlock': {
              return (
                <DatoGatsbyImage
                  image={block.image?.gatsbyImageData}
                  alt={block.image?.alt || ''}
                  key={block.id}
                  css={styles.image}
                  sizes={'90vw'}
                />
              )
            }
          }
        })}
      </div>
      {getFormRenderer()}
    </ArticleContainer>
  )
}

export const BlackbaudFormFragment = graphql`
  fragment BlackbaudForm on DatoCmsBlackbaudForm {
    __typename
    id: originalId
    formName
    heading
    intro {
      ... on DatoCmsBodyTextBlock {
        ...BodyTextBlock
      }
      ... on DatoCmsGivingLevelSection {
        ...GivingLevelSection
      }
      ... on DatoCmsImageBlock {
        __typename
        id: originalId
        image {
          gatsbyImageData(width: 1280, imgixParams: { q: 75 })
          alt
        }
      }
    }
    formId
    oGId
    bboxVersion
    slug
    seoMetaTags {
      tags
    }
  }
`
