import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'select'> {
  options: {
    label?: string | null
    value?: string | null
  }[]
  value?: string
}

export const FilterDropdown = ({
  options,
  value,
  ...props
}: Props): JSX.Element => {
  const svgString = `
    <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L12 12L23 1" stroke="${colors.red}" stroke-width="2"/>
    </svg>  
  `
  const svgEncoded = btoa(svgString)
  const styles = {
    select: css`
      appearance: none;
      background: transparent;
      font-family: var(--ff-display);
      font-size: var(--fs-30);
      border: none;
      border-bottom: 2px solid ${colors.red};
      padding: 0.25em 1em 0.25em 0;
      margin: 0;
      color: ${colors.gray20};

      background-image: url(data:image/svg+xml;base64,${svgEncoded});
      background-size: 0.8em 0.5em;
      background-position: 100% 55%;
      background-repeat: no-repeat;
      filter: grayscale(100%) brightness(2.5);
      transition:
        filter 300ms ease,
        color 300ms ease;
      &:hover {
        filter: none;
        color: ${colors.red};
      }
    `,
    option: css`
      font-size: var(--fs-16);
      font-family: var(--ff-body);
    `,
  }
  return (
    <select
      css={styles.select}
      value={value}
      {...props}
    >
      {options.map((option, i) => (
        <option
          css={styles.option}
          key={i}
          value={option.value || ''}
        >
          {option.label}
        </option>
      ))}
    </select>
  )
}
