import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { BodyTextBlock } from '@/features/common/'
import { bodyGrid, bodyHeading } from '@/features/page-sections'
import { colors } from '@/theme/variables'

import { DirectionsBlock } from './DirectionsBlock'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?: Queries.DirectionsSectionFragment | null
  bgColor: 'WHITE' | 'GRAY'
}

export const DirectionsSection = ({
  data,
  bgColor,
  ...props
}: Props): JSX.Element => {
  const styles = {
    section: css`
      ${bodyGrid}
      background: ${bgColor === 'WHITE'
        ? '#fff'
        : bgColor === 'GRAY' && colors.gray95};
    `,
    heading: css`
      ${bodyHeading}
    `,
    blocks: css``,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      <h2 css={styles.heading}>{data?.heading}</h2>
      <div css={styles.blocks}>
        {data?.blocks?.map((block, i) => {
          switch (block?.__typename) {
            case 'DatoCmsBodyTextBlock':
              return (
                <BodyTextBlock
                  key={i}
                  data={block}
                />
              )
            case 'DatoCmsDirectionsBlock':
              return (
                <DirectionsBlock
                  key={i}
                  data={block}
                  css={
                    i === (data?.blocks?.length || 0) - 1 && {
                      marginBottom: `var(--row-36)`,
                    }
                  }
                />
              )
          }
        })}
      </div>
    </section>
  )
}

export const DirectionsSectionFragment = graphql`
  fragment DirectionsSection on DatoCmsDirectionsSection {
    __typename
    id: originalId
    heading
    blocks {
      ... on DatoCmsBodyTextBlock {
        ...BodyTextBlock
      }
      ... on DatoCmsDirectionsBlock {
        ...DirectionsBlock
      }
    }
  }
`
