import { css } from '@emotion/react'
import { Record } from 'datocms-structured-text-utils'
import { Link } from 'gatsby'
import { ComponentPropsWithoutRef, ReactNode } from 'react'

import { DatoLinkIcon } from '../DatoLink/DatoLinkIcon'
import { FormLink } from '../DatoLink/FormLink'
import { TertiaryArticleLink } from '../DatoLink/TertiaryArticleLink'

export interface DatoStructuredTextLinkData extends Record {
  slug: string
  pageHeading?: string | null
  heading?: string | null
  divisionName?: string | null
  pageName?: string | null
  pageTitle?: string | null
}

interface Props extends ComponentPropsWithoutRef<'a'> {
  data?: Record | null
  children?: ReactNode
  inline?: boolean
  disableLightboxLinks?: boolean
}

export const DatoStructuredTextLink = ({
  data: untypedData,
  children,
  inline,
  disableLightboxLinks,
  ...props
}: Props): JSX.Element => {
  const data = untypedData as
    | DatoStructuredTextLinkData
    | null
    | undefined
  const heading =
    data?.pageHeading ||
    data?.heading ||
    data?.divisionName ||
    data?.pageName ||
    data?.pageTitle

  const styles = {
    inline: css`
      position: relative;
      color: var(--link-color, currentColor);
      --font-weight: 500;
      font-weight: var(--font-weight);
      display: block;
      max-width: fit-content;
      padding: 0.5em 0;
      padding-right: 1em;
      margin: 0.5em 0;
      text-transform: uppercase;
      letter-spacing: 0.025em;
      text-decoration: none;
      line-height: 1;
      transition: all 200ms ease;
      > span {
        position: relative;
      }
    `,
  }
  switch (data?.__typename) {
    case 'DatoCmsTertiaryArticle':
      return (
        <TertiaryArticleLink
          data={{
            linkText: inline ? heading : children,
            article: {
              slug: data?.slug,
            },
          }}
          showIcon={inline}
          css={inline && styles.inline}
          disableLightboxLinks={disableLightboxLinks}
          {...props}
        />
      )
    case 'DatoCmsBlackbaudForm':
      return (
        <FormLink
          data={{
            linkText: inline ? heading : children,
            form: {
              __typename: 'DatoCmsBlackbaudForm',
              slug: data?.slug as string,
            },
          }}
          showIcon={inline}
          css={inline && styles.inline}
          disableLightboxLinks={disableLightboxLinks}
          {...props}
        />
      )
    default:
      return (
        <Link
          to={`/${data?.slug ? data.slug + '/' : ''}`}
          css={inline && styles.inline}
          {...props}
        >
          {inline
            ? (data?.pageHeading as string) || 'Brearley Home'
            : children}
          {inline && <DatoLinkIcon iconType="LIGHTBOX" />}
        </Link>
      )
  }
}
