import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { DatoGatsbyImage } from '@/features/common/'
import { DatoStructuredText } from '@/features/common/'
import { DatoLink } from '@/features/dato-link'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.ThumbnailLinkFragment | null
}

export const ThumbnailLink = ({
  data,
  ...props
}: Props): JSX.Element => {
  const styles = {
    container: css``,
    image: css`
      width: 100%;
    `,
    title: css`
      font-size: var(--fs-36);
      margin: 0.6em 0 0.25em;
    `,
    body: css`
      font-weight: 350;
      p {
        margin: 0 0 0.5em;
      }
    `,
    link: css`
      margin-top: 0;
      color: ${colors.red};
      @media (hover: hover) {
        &:hover {
          color: ${colors.redDark};
        }
      }
    `,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      <DatoGatsbyImage
        css={styles.image}
        image={data?.thumbnailImage?.gatsbyImageData}
        alt={data?.thumbnailImage?.alt || ''}
      />
      <h3 css={styles.title}>{data?.heading}</h3>
      <div css={styles.body}>
        <DatoStructuredText data={data?.description} />
      </div>
      <DatoLink
        css={styles.link}
        data={data?.link?.[0]}
        showIcon
      />
    </div>
  )
}

export const ThumbnailLinkFragment = graphql`
  fragment ThumbnailLink on DatoCmsThumbnailLink {
    thumbnailImage {
      gatsbyImageData(
        width: 720
        sizes: "(max-width: 540px) 100vw, (max-width: 720px) 50vw, 33vw"
        imgixParams: {
          fit: "crop"
          crop: "focalpoint"
          q: 50
          ar: "5:3"
        }
      )
      alt
    }
    heading
    description {
      value
    }
    link {
      ... on DatoCmsPageLink {
        ...PageLink
      }
      ... on DatoCmsExternalLink {
        ...ExternalLink
      }
      ... on DatoCmsTertiaryArticleLink {
        ...TertiaryArticleLink
      }
      ... on DatoCmsPdfLink {
        ...PdfLink
      }
      ... on DatoCmsFormLink {
        ...FormLink
      }
    }
  }
`
