import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

import { GatsbyImageFocused } from '@/features/common/'
import { absoluteFill, aspectRatio, mq } from '@/theme/mixins'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.HighlightSectionFragment['image']
  textAlignment: 'LEFT' | 'RIGHT'
  color?: 'WHITE' | 'GRAY'
  specialBgColor?: 'WHITE' | 'DARK' | 'RED'
}

export const HighlightSectionImage = ({
  data,
  textAlignment,
  specialBgColor,
  ...props
}: Props): JSX.Element => {
  const styles = {
    image: css`
      position: relative;
      min-height: calc((100vw - 2 * var(--margin) - var(--gtr-36)) / 2);
      order: ${textAlignment === 'LEFT' && 2};
      [data-gatsby-image-wrapper] {
        ${absoluteFill}
      }
      ${mq().ms} {
        order: 1;
        ${aspectRatio(3 / 2)}
      }
      ${specialBgColor &&
      css`
        z-index: 2;
        ${specialBgColor === 'DARK' &&
        css`
          transform: translateY(calc(-1 * var(--row-36)));
        `}
        ${specialBgColor === 'RED' &&
        css`
          transform: translateY(calc(var(--row-36)));
          z-index: 3;
        `}
        ${specialBgColor === 'WHITE' &&
        css`
          margin: var(--row-72) 0;
        `}
        ${mq().ms} {
          transform: translateY(calc(-1 * var(--row-36)));
          margin: 0;
        }
      `}
    `,
  }
  return (
    <GatsbyImageFocused
      image={
        specialBgColor ? data?.specialImageData : data?.defaultImageData
      }
      aspectRatio={1}
      css={styles.image}
      data={data}
      {...props}
    />
  )
}
