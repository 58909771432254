import { graphql, useStaticQuery } from 'gatsby'
import { Fragment, useLayoutEffect } from 'react'

export type SeoMetaTags = {
  tags: (
    | {
        tagName: 'title'
        content: string
      }
    | {
        tagName: 'meta'
        attributes: {
          name?: string
          property?: string
          content: string
        }
      }
  )[]
}

interface Props {
  seoMetaTags?:
    | {
        tags: Record<string, unknown> | Record<string, unknown>[] | null
      }
    | null
    | undefined
  hideSuffix?: boolean
  isArticle?: boolean
  lang?: string
  noIndex?: boolean
}

export const Seo = ({
  seoMetaTags,
  hideSuffix,
  isArticle,
  lang = 'en',
  noIndex,
}: Props): JSX.Element => {
  const { site } = useStaticQuery<Queries.SeoQuery>(graphql`
    query Seo {
      site: datoCmsSite {
        globalSeo {
          titleSuffix
        }
      }
    }
  `)

  const { titleSuffix } = site?.globalSeo || {}

  useLayoutEffect(() => {
    document.documentElement.lang = lang
  }, [lang])

  return (
    <Fragment>
      {(seoMetaTags as unknown as SeoMetaTags)?.tags?.map((tag, i) => {
        if (tag?.tagName === 'title') {
          return (
            <title key={i}>
              {!hideSuffix
                ? tag.content
                : tag.content?.replace(titleSuffix || '', '')}
            </title>
          )
        }
        if (tag.tagName === 'meta') {
          if (!isArticle) {
            if (tag.attributes.property === 'og:type') {
              return (
                <meta
                  property="og:type"
                  content="website"
                  key={i}
                />
              )
            }
            if (
              tag.attributes.property === 'article:modified_time' ||
              tag.attributes.property === 'article:published_time'
            ) {
              return null
            }
          }
          return (
            <meta
              name={tag.attributes.name || undefined}
              property={tag.attributes.property || undefined}
              content={tag.attributes.content}
              key={i}
            />
          )
        }
      })}
      {noIndex && (
        <meta
          name="robots"
          content="noindex, nofollow"
        />
      )}
    </Fragment>
  )
}
