import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { rgba } from 'polished'
import { ComponentPropsWithoutRef, ElementType } from 'react'

import { colors } from '@/theme/variables'

import { BodyTextBlock } from '../BodyTextBlock/BodyTextBlock'

interface Props extends ComponentPropsWithoutRef<'li'> {
  data?: Queries.ListItemFragment | null
  headingLevel?: 2 | 3 | 4 | 5 | 6
}

export const ListItem = ({
  data,
  headingLevel = 3,
  ...props
}: Props): JSX.Element => {
  const Heading = `h${headingLevel}` as ElementType
  const Subheading = `h${Math.min(headingLevel + 1, 6)}` as ElementType

  const styles = {
    listItem: css`
      margin: 3em 0;
      &:not(:first-of-type) {
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        padding-top: 2em;
      }
    `,
    heading: css`
      font-size: var(--fs-36);
      margin: 0 0 0.5em;
      &:after {
        content: '';
        display: block;
        width: 1em;
        margin-top: 0.5em;
        height: 2px;
        background: ${colors.red};
      }
    `,
    subheading: css`
      font-size: var(--fs-18);
      margin: 1em 0;
      color: ${rgba(colors.gray20, 0.6)};
    `,
    body: css`
      max-width: 90ch;
    `,
  }
  return (
    <li
      css={styles.listItem}
      {...props}
    >
      <Heading css={styles.heading}>{data?.heading}</Heading>
      {data?.subheading && (
        <Subheading css={styles.subheading}>
          {data?.subheading}
        </Subheading>
      )}
      <BodyTextBlock
        css={styles.body}
        data={data?.body}
      />
    </li>
  )
}

export const ListItemFragment = graphql`
  fragment ListItem on DatoCmsListItem {
    __typename
    id: originalId
    heading
    subheading
    body {
      ...BodyTextBlock
    }
  }
`
