import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { bodyGrid, bodyHeading } from '@/features/page-sections'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { LeaderThumbnail } from './LeaderThumbnail'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?: Queries.LeadershipSectionFragment | null
  bgColor: 'WHITE' | 'GRAY'
}

export const LeadershipSection = ({
  data,
  bgColor,
  ...props
}: Props): JSX.Element => {
  const styles = {
    section: css`
      ${bodyGrid}
      background: ${bgColor === 'WHITE'
        ? '#fff'
        : bgColor === 'GRAY' && colors.gray95};
    `,
    heading: css`
      ${bodyHeading}
    `,
    leaders: css`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: var(--margin);
      grid-row-gap: var(--row-54);
      justify-items: center;
      min-width: fit-content;
      ${mq().ms} {
        margin-top: 1em;
      }
      ${mq().s} {
        grid-template-columns: 1fr 1fr;
      }
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      <h2 css={styles.heading}>{data?.heading}</h2>
      <div css={styles.leaders}>
        {data?.leaders?.map((leader, i) => (
          <LeaderThumbnail
            data={leader}
            key={i}
          />
        ))}
      </div>
    </section>
  )
}

export const LeadershipSectionFragment = graphql`
  fragment LeadershipSection on DatoCmsLeadershipSection {
    __typename
    id: originalId
    heading
    leaders {
      ...LeaderThumbnail
    }
  }
`
