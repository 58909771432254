import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { DatoGatsbyImage } from '@/features/common/'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data: Queries.MatriculationSchoolFragment | null
}

export const MatriculationSchool = ({
  data,
  ...props
}: Props): JSX.Element => {
  const styles = {
    container: css`
      display: flex;
      align-items: center;
    `,
    crest: css`
      width: var(--fs-84);
      mix-blend-mode: multiply;
      align-self: flex-start;
    `,
    text: css`
      margin-left: 1rem;
      padding-left: 1rem;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    `,
    name: css`
      font-family: var(--ff-display);
      font-size: var(--fs-30);
      margin: 0;
    `,
    matriculated: css`
      font-family: var(--ff-display);
      font-size: var(--fs-30);
      color: ${colors.gray65};
      margin: 0;
      font-weight: 400;
    `,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      <DatoGatsbyImage
        css={styles.crest}
        image={data?.crest?.gatsbyImageData}
        alt={data?.schoolName || ''}
      />
      <div css={styles.text}>
        <h3 css={styles.name}>{data?.schoolName}</h3>
        <h4 css={styles.matriculated}>
          {data?.numberMatriculated} Attending
        </h4>
      </div>
    </div>
  )
}

export const MatriculationSchoolFragment = graphql`
  fragment MatriculationSchool on DatoCmsMatriculationSchool {
    __typename
    id: originalId
    schoolName
    crest {
      gatsbyImageData(width: 120)
    }
    numberMatriculated
  }
`
